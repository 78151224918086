import React from 'react';
import ReactDOM from 'react-dom/client';
import {RecoilRoot} from "recoil";
import ReactGA from "react-ga"
import {BrowserRouter} from "react-router-dom";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID) //구글 애널리틱 연결
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RecoilRoot>
        <BrowserRouter>
        </BrowserRouter>
    </RecoilRoot>
);
